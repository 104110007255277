<template>
  <about-us-page :info="getABOUT_US" @openFile="fileDownload" />
</template>

<script>
import AboutUsPage from "../../../components/pages/cabinet/about/AboutUsPage";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AboutUs",
  components: { AboutUsPage },
  computed: {
    ...mapGetters("AboutModule", ["getABOUT_US"]),
  },
  methods: {
    ...mapActions("regModule", ["DOWNLOAD_FILE"]),
    fileDownload({id, text_name, origin_name}){
      const index = origin_name.split('.').length -1
      this.DOWNLOAD_FILE({id: id, name: `${text_name}.${origin_name.split('.')[index]}`})
    }
  },
};
</script>

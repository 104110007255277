<template>
    <div class="about-body">
        <div class="about-body__container">
            <h1>О нас</h1>
            <section v-if="info.text">
                <div class="anketa" v-html="info.text" />
            </section>
            <section v-if="info.files" class="about-body__container_files">
                <rwm-file-loaded
                    class="about-body__container_files-list"
                    v-for="file in info.files"
                    :key="file.id"
                    :filename="name(file)"
                    :has-delete="false"
                    width="100%"
                    @openFile="$emit('openFile', file)"
                    dont-cut/>
            </section>
        </div>
    </div>
</template>

<script>
    import RwmFileLoaded from "../../../RWMInputFile/RwmFileLoaded";
    export default {
        name: "AboutUsPage",
        components: {RwmFileLoaded},
        props: ["info"],
        methods: {
            name(file) {
                const index = file.origin_name.split('.').length -1
                return `${file.text_name}.${file.origin_name.split('.')[index]}`
            }
        }
    }
</script>

<style scoped lang="scss">
    .about-body {
        flex: 1 1 auto;
        margin-bottom: 16px;
        text-align: left;
        @media screen and (min-width: 961px) {
            padding: 0;
        }

        &__container {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            &_files {
                display: flex;
                flex-wrap: wrap;
                row-gap: 24px;
                &-list {
                    display: flex;
                    flex: 1 1 100%;
                }
            }
        }
    }
</style>
